import { calculateLevel, xpForNextLevel, calculateXPForLevel } from './levelSystem';
import { getLevelRank } from './rankSystem';
import axios from 'axios';

export function handleXPUpdate(totalXP, setUserXP, setUserLevel, setXPProgress, setXPForNext, setLevelRank) {
  const level = calculateLevel(totalXP);
  const currentLevelXP = calculateXPForLevel(level);
  const nextLevelXP = xpForNextLevel(level);
  const progress = totalXP - currentLevelXP;
  const forNext = nextLevelXP - currentLevelXP;
  
  setUserXP(totalXP);
  setUserLevel(level);
  setXPProgress(progress);
  setXPForNext(forNext);
  
  const currentRank = getLevelRank(level);
  setLevelRank(currentRank);
}

export function handleXPGain(userId, currentXP, xpGained, handleXPUpdate) {
  const newTotalXP = currentXP + xpGained;
  const newLevel = calculateLevel(newTotalXP);
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  
  axios.post('/api/updateUser', { xp: newTotalXP, level: newLevel }, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(response => {
      handleXPUpdate(response.data.xp);
    })
    .catch(error => {
      console.error('Error updating XP:', error);
    });
}