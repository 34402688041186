import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { NavigationBar } from './NavigationBar';
import { Dashboard } from './Dashboard';
import { Quizzes } from './Quizzes';
import { Footer } from './Footer';
import { UserProfile } from './UserProfile';
import { EditProfile } from './EditProfile';
import { SignUpModal, LoginModal } from './AuthModals';
import { useAuth } from '../hooks/useAuth';
import { UsersPage } from './UsersPage';

function CosmicQuizGalaxyContent() {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const auth = useAuth();

  const handleNavigation = (section) => {
    navigate(`/${section}`);
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    const result = await auth.handleSignUp(auth.username, password);
    if (result.success) {
      setShowSignUp(false);
      setPassword('');
      setMessage('');
    } else {
      setMessage(result.message);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const result = await auth.handleLogin(auth.username, password, rememberMe);
    if (result.success) {
      setShowLogin(false);
      setPassword('');
      setMessage('');
    } else {
      setMessage(result.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#0a0a20] text-white overflow-hidden">
      <div id="universe-background" className="fixed inset-0 z-0" />
      
      <NavigationBar 
        handleNavigation={handleNavigation}
        isAuthenticated={auth.isAuthenticated}
        username={auth.username}
        userLevel={auth.userLevel}
        userXP={auth.userXP}
        xpProgress={auth.xpProgress}
        xpForNext={auth.xpForNext}
        userRole={auth.userRole}
        levelRank={auth.levelRank}
        setShowSignUp={setShowSignUp}
        setShowLogin={setShowLogin}
        handleLogout={auth.handleLogout}
        profilePicture={auth.profilePicture}
        changeUserRole={auth.changeUserRole}
        changeUserLevel={auth.changeUserLevel}
      />

      <main className="flex-1 p-6 overflow-auto z-10">
        <div className="max-w-6xl mx-auto space-y-8">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/quizzes" element={<Quizzes />} />
            <Route 
              path="/profile/:username" 
              element={
                auth.isAuthenticated ? 
                  <UserProfile /> : 
                  <Navigate to="/" replace />
              } 
            />
            <Route 
              path="/profile/edit/:username" 
              element={
                auth.isAuthenticated ? 
                  <EditProfile /> : 
                  <Navigate to="/" replace />
              } 
            />
            <Route 
              path="/users" 
              element={
                <UsersPage 
                  isOwner={auth.username === 'Toght'} 
                  changeUserRoleAdmin={auth.changeUserRoleAdmin}
                  changeUserLevelAdmin={auth.changeUserLevelAdmin}
                />
              } 
            />
          </Routes>
          
          {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        </div>
      </main>

      <SignUpModal 
        showSignUp={showSignUp}
        setShowSignUp={setShowSignUp}
        username={auth.username}
        setUsername={auth.setUsername}
        password={password}
        setPassword={setPassword}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        handleSignUp={handleSignUpSubmit}
        setMessage={setMessage}
      />

      <LoginModal 
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        username={auth.username}
        setUsername={auth.setUsername}
        password={password}
        setPassword={setPassword}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        handleLogin={handleLoginSubmit}
        setMessage={setMessage}
      />

      <Footer />
    </div>
  );
}

export default function CosmicQuizGalaxy() {
  return (
    <Router>
      <CosmicQuizGalaxyContent />
    </Router>
  );
}