import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Progress } from "./ui/progress";
import { UserIcon, BarChartIcon, BellIcon, BookmarkIcon, KeyIcon, SettingsIcon, HelpCircleIcon, LogOutIcon } from "lucide-react";
import { DropdownItem } from './ui/DropdownItem';
import { rankColors } from '../constants/colors';

const DEFAULT_AVATAR = "/default-avatar.png";

export function UserMenu({ 
  username,
  userLevel,
  userXP,
  xpProgress,
  xpForNext,
  userRole,
  levelRank,
  changeUserRole,
  changeUserLevel,
  handleLogout,
  profilePicture
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const progressPercentage = xpForNext > 0 ? (xpProgress / xpForNext) * 100 : 0;

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={toggleDropdown}>
        <img
          src={profilePicture || DEFAULT_AVATAR}
          alt={username}
          className="w-full h-full object-cover object-center"
        />
      </div>
      {showDropdown && (
        <div className="absolute right-0 top-full mt-2 w-72 bg-[#1a1a3a] rounded-md shadow-lg py-1 z-50">
          <UserInfo 
            username={username}
            userRole={userRole}
            userLevel={userLevel}
            levelRank={levelRank}
            xpProgress={xpProgress}
            xpForNext={xpForNext}
            progressPercentage={progressPercentage}
            profilePicture={profilePicture}
          />
          <TestButtons changeUserRole={changeUserRole} changeUserLevel={changeUserLevel} />
          <MenuItems handleLogout={handleLogout} username={username} />
        </div>
      )}
    </div>
  );
}

function UserInfo({ username, userRole, userLevel, levelRank, xpProgress, xpForNext, progressPercentage, profilePicture }) {
  return (
    <div className="px-4 py-3 border-b border-gray-700">
      <div className="flex items-center space-x-3 mb-2">
        <div className="w-12 h-12 rounded-full overflow-hidden border-2 border-indigo-400">
          <img
            src={profilePicture || DEFAULT_AVATAR}
            alt={username}
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div>
          <p className="text-sm font-semibold text-white">{username || 'User'}</p>
          {userRole && (
            <span className={`text-xs font-bold px-2 py-1 rounded ${getRoleColor(userRole)}`}>
              {userRole.toUpperCase()}
            </span>
          )}
        </div>
      </div>
      <div className="mt-2">
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <span className="text-xs font-semibold inline-block text-white">
              Level {userLevel} - 
              <span className={`ml-1 font-bold ${rankColors[levelRank]}`}>
                {levelRank}
              </span>
            </span>
            <span className="text-xs font-semibold inline-block text-purple-400">
              {progressPercentage.toFixed(0)}%
            </span>
          </div>
          <Progress value={progressPercentage} className="h-2 bg-purple-200" />
        </div>
        <p className="text-xs text-gray-400 mt-1">
          {xpProgress} / {xpForNext} XP to next level
        </p>
      </div>
    </div>
  );
}

function TestButtons({ changeUserRole, changeUserLevel }) {
  return (
    <div className="px-4 py-2 border-b border-gray-700">
      <div className="flex justify-between">
        <button
          onClick={changeUserRole}
          className="text-xs bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded"
        >
          Change Role
        </button>
        <button
          onClick={changeUserLevel}
          className="text-xs bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded"
        >
          Level Up
        </button>
      </div>
    </div>
  );
}

function MenuItems({ handleLogout, username }) {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`/profile/${username}`);
  };

  return (
    <>
      <div className="py-1">
        <div onClick={handleProfileClick} className="cursor-pointer">
          <DropdownItem icon={UserIcon} label="Profile" className="text-left" />
        </div>
        <DropdownItem icon={BarChartIcon} label="Stats" className="text-left" />
        <DropdownItem icon={BellIcon} label="Notifications" className="text-left" />
        <DropdownItem icon={BookmarkIcon} label="Saved Quizzes" className="text-left" />
      </div>
      <div className="border-t border-gray-700 py-1">
        <DropdownItem icon={KeyIcon} label="Change Password" className="text-left" />
        <DropdownItem icon={SettingsIcon} label="Settings" className="text-left" />
        <DropdownItem icon={HelpCircleIcon} label="Help & Support" className="text-left" />
      </div>
      <div className="border-t border-gray-700 py-1">
        <DropdownItem icon={LogOutIcon} label="Log Out" onClick={handleLogout} className="text-left text-red-400 hover:text-red-300" />
      </div>
    </>
  );
}

function getRoleColor(role) {
  switch (role) {
    case 'MEMBER':
      return 'bg-yellow-500 text-yellow-900 border border-yellow-400';
    case 'MODERATOR':
      return 'bg-green-500 text-green-900 border border-green-400';
    case 'ADMIN':
      return 'bg-red-500 text-red-900 border border-red-400';
    case 'OWNER':
      return 'bg-purple-500 text-purple-900 border border-purple-400';
    default:
      return 'bg-gray-500 text-gray-900 border border-gray-400';
  }
}