import React from 'react';

export function DropdownItem({ icon: Icon, label, onClick, className }) {
  return (
    <a
      href="#"
      className={`block px-4 py-2 text-sm text-gray-300 hover:bg-[#3a3a5a] hover:text-white ${className}`}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick();
      }}
    >
      <Icon className="inline-block w-4 h-4 mr-2" />
      {label}
    </a>
  );
}