export const roleColors = {
  MEMBER: 'text-yellow-300',
  MODERATOR: 'text-green-400',
  ADMIN: 'text-red-400',
  OWNER: 'text-purple-400'
};

export const rankColors = {
  'Novice': 'text-gray-400',
  'Rookie': 'text-green-400',
  'Trainee': 'text-blue-400',
  'Learner': 'text-indigo-400',
  'Apprentice': 'text-purple-400',
  'Quiz Enthusiast': 'text-pink-400',
  'Explorer': 'text-red-400',
  'Challenger': 'text-orange-400',
  'Thinker': 'text-yellow-400',
  'Adept': 'text-lime-400',
  'Expert': 'text-emerald-400',
  'Quiz Mastermind': 'text-teal-400',
  'Quiz Wizard': 'text-cyan-400',
  'Quiz Sage': 'text-sky-400',
  'Quiz Champion': 'text-blue-500',
  'Quiz Maestro': 'text-indigo-500',
  'Brainiac': 'text-violet-500',
  'Quiz Overlord': 'text-purple-500',
  'Quiz Legend': 'text-fuchsia-500',
  'Quiz Titan': 'text-pink-500',
  'Supreme Quizmaster': 'text-rose-500',
  'Cosmic Quizmaster': 'text-red-500'
};