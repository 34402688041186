import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { fetchUserProfile, updateUserProfile } from '../api/userProfileApi';

export function EditProfile() {
  const [profileData, setProfileData] = useState({
    description: '',
    profilePicture: ''
  });
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await fetchUserProfile(username);
        setProfileData({
          description: userData.description || '',
          profilePicture: userData.profilePicture || ''
        });
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load user data');
        setIsLoading(false);
      }
    };

    loadUserData();
  }, [username]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Clear any previous errors
    try {
      const formData = new FormData();
      formData.append('description', profileData.description);
      if (file) {
        formData.append('profilePicture', file);
      }

      const updatedUser = await updateUserProfile(username, formData);
      if (updatedUser) {
        navigate(`/profile/${username}`);
      } else {
        setError('Failed to update profile: No response from server');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError(`Failed to update profile: ${err.response?.data?.message || err.message}`);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2">
        <CardHeader>
          <CardTitle className="text-3xl font-bold text-white">Edit Profile</CardTitle>
        </CardHeader>
        <CardContent>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="description" className="block text-indigo-300 mb-2">Description</label>
              <textarea
                id="description"
                name="description"
                value={profileData.description}
                onChange={handleInputChange}
                className="w-full p-2 bg-indigo-800/30 rounded text-white"
                rows="4"
              />
            </div>
            <div>
              <label htmlFor="profilePicture" className="block text-indigo-300 mb-2">Profile Picture</label>
              <input
                type="file"
                id="profilePicture"
                name="profilePicture"
                onChange={handleFileChange}
                className="w-full p-2 bg-indigo-800/30 rounded text-white"
                accept="image/*"
              />
            </div>
            <Button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white">
              Save Changes
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}