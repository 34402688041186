import React from 'react';

export function Avatar({ children, className, ...props }) {
  return <div className={`inline-flex items-center justify-center overflow-hidden rounded-full ${className}`} {...props}>{children}</div>;
}

export function AvatarImage({ src, alt, className, ...props }) {
  return <img src={src} alt={alt} className={`w-full h-full object-cover ${className}`} {...props} />;
}

export function AvatarFallback({ children, className, ...props }) {
  return <div className={`flex items-center justify-center w-full h-full bg-gray-300 text-gray-600 ${className}`} {...props}>{children}</div>;
}