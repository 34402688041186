import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import { Input } from "./ui/input"; // Make sure you have this component
import { SearchIcon } from "lucide-react"; // Import the SearchIcon
import axios from 'axios';
import { calculateXPForLevel, xpForNextLevel } from '../utils/levelSystem';

export function UsersPage() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter(user => 
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
      setFilteredUsers(response.data);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to load users: ' + (err.response?.data?.message || err.message));
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-white mb-6">Users</h1>
      <div className="mb-6 relative">
        <Input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10 bg-indigo-900/50 border-indigo-500 text-white"
        />
        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-400" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredUsers.map(user => (
          <UserCard 
            key={user._id} 
            user={user} 
            navigate={navigate}
          />
        ))}
      </div>
    </div>
  );
}

function UserCard({ user, navigate }) {
  const currentLevelXP = calculateXPForLevel(user.level);
  const nextLevelXP = xpForNextLevel(user.level);
  const xpProgress = user.xp - currentLevelXP;
  const xpForNext = nextLevelXP - currentLevelXP;
  const progressPercentage = (xpProgress / xpForNext) * 100;

  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2">
      <CardContent className="p-6 space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-indigo-400">
              <img
                src={user.profilePicture || "/default-avatar.png"}
                alt={user.username}
                className="w-full h-full object-cover object-center"
              />
            </div>
            <div>
              <h2 className="text-xl font-bold text-white">{user.username}</h2>
              <p className="text-indigo-300">Level {user.level}</p>
            </div>
          </div>
          <RoleBadge role={user.role} />
        </div>
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-indigo-300">XP Progress</span>
            <span className="text-indigo-200 font-semibold">{progressPercentage.toFixed(0)}%</span>
          </div>
          <Progress value={progressPercentage} className="h-2 bg-indigo-950" />
        </div>
        <Button 
          className="w-full bg-blue-500 hover:bg-blue-600 text-white"
          onClick={() => navigate(`/profile/${user.username}`)}
        >
          View Profile
        </Button>
      </CardContent>
    </Card>
  );
}

function RoleBadge({ role }) {
  const getRoleColor = (role) => {
    switch (role) {
      case 'MEMBER':
        return 'bg-yellow-500 text-yellow-900 border border-yellow-400';
      case 'MODERATOR':
        return 'bg-green-500 text-green-900 border border-green-400';
      case 'ADMIN':
        return 'bg-red-500 text-red-900 border border-red-400';
      case 'OWNER':
        return 'bg-purple-500 text-purple-900 border border-purple-400';
      default:
        return 'bg-gray-500 text-gray-900 border border-gray-400';
    }
  };

  return (
    <span className={`text-xs font-bold px-2 py-1 rounded ${getRoleColor(role)}`}>
      {role}
    </span>
  );
}