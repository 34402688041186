import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Badge } from "./ui/badge";
import { Progress } from "./ui/progress";
import { UserIcon, TrophyIcon, ActivityIcon, StarIcon, CheckCircleIcon, BookOpenIcon, UsersIcon, BrainIcon } from "lucide-react";

export function Dashboard() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  return (
    <div className="space-y-8">
      <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.8)]">
        Blight Trivia Dashboard
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {isAuthenticated && <UserProfile />}
        <TopUsers />
        {isAuthenticated && <QuizStats />} {/* New component to replace UserActivity */}
      </div>
      <WebsiteStats />
    </div>
  );
}

function UserProfile() {
  const user = {
    name: "Toght on Steroids",
    level: 42,
    xp: 8750,
    xpToNextLevel: 10000,
    questionsAnswered: 1337,
    points: 87500,
    rank: "Elite Quizzer",
    avatar: "https://i.pravatar.cc/150?img=3"
  };

  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden">
      <CardHeader className="bg-indigo-800/50">
        <CardTitle className="text-2xl font-bold text-white flex items-center">
          <UserIcon className="mr-2 h-6 w-6 text-blue-400" />
          Your Profile
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6 space-y-4">
        <div className="flex items-center space-x-4">
          <Avatar className="w-20 h-20 border-2 border-indigo-400">
            <AvatarImage src={user.avatar} alt={user.name} />
            <AvatarFallback>{user.name.slice(0, 2)}</AvatarFallback>
          </Avatar>
          <div>
            <h2 className="text-xl font-bold text-white">{user.name}</h2>
            <Badge className="bg-indigo-700 text-white">{user.rank}</Badge>
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-indigo-300">Level {user.level}</span>
            <span className="text-indigo-200 font-semibold">{user.xp} / {user.xpToNextLevel} XP</span>
          </div>
          <Progress value={(user.xp / user.xpToNextLevel) * 100} className="h-2 bg-indigo-950">
            <div
              className="h-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full"
              style={{ width: `${(user.xp / user.xpToNextLevel) * 100}%` }}
            />
          </Progress>
        </div>
        <div className="grid grid-cols-2 gap-4 text-center">
          <div className="bg-indigo-800/50 rounded-lg p-2">
            <CheckCircleIcon className="h-6 w-6 text-green-400 mx-auto" />
            <div className="text-sm text-indigo-300 mt-1">Questions Answered</div>
            <div className="text-xl font-bold text-white">{user.questionsAnswered}</div>
          </div>
          <div className="bg-indigo-800/50 rounded-lg p-2">
            <StarIcon className="h-6 w-6 text-yellow-400 mx-auto" />
            <div className="text-sm text-indigo-300 mt-1">Total Points</div>
            <div className="text-xl font-bold text-white">{user.points}</div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TopUsers() {
  const topUsers = [
    { period: 'Daily', name: 'QuizWhiz', score: 1200, avatar: 'https://i.pravatar.cc/150?img=1' },
    { period: 'Weekly', name: 'TriviaKing', score: 8500, avatar: 'https://i.pravatar.cc/150?img=2' },
    { period: 'Monthly', name: 'BrainiacChamp', score: 35000, avatar: 'https://i.pravatar.cc/150?img=3' },
  ];

  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden">
      <CardHeader className="bg-indigo-800/50">
        <CardTitle className="text-2xl font-bold text-white flex items-center">
          <TrophyIcon className="mr-2 h-6 w-6 text-yellow-400" />
          Top Users
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {topUsers.map((user, index) => (
          <div key={index} className="flex items-center justify-between p-4 border-b border-indigo-700 last:border-b-0 hover:bg-indigo-800/30 transition-colors">
            <div className="flex items-center space-x-3">
              <Avatar className="w-10 h-10 border-2 border-indigo-400">
                <AvatarImage src={user.avatar} alt={user.name} />
                <AvatarFallback>{user.name.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <div>
                <p className="font-semibold text-white">{user.name}</p>
                <Badge variant="secondary" className="bg-indigo-700 text-xs">
                  {user.period} Champion
                </Badge>
              </div>
            </div>
            <div className="flex items-center space-x-1">
              <StarIcon className="h-4 w-4 text-yellow-400" />
              <p className="text-yellow-400 font-bold">{user.score}</p>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

function QuizStats() {
  // Placeholder data - replace with actual user quiz stats
  const stats = {
    quizzesTaken: 42,
    averageScore: 85,
    bestCategory: "Science",
    totalPoints: 3500
  };

  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden">
      <CardHeader className="bg-indigo-800/50">
        <CardTitle className="text-2xl font-bold text-white flex items-center">
          <BrainIcon className="mr-2 h-6 w-6 text-blue-400" />
          Your Quiz Stats
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6 space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-indigo-800/30 rounded-lg p-3 text-center">
            <p className="text-sm text-indigo-300">Quizzes Taken</p>
            <p className="text-2xl font-bold text-white">{stats.quizzesTaken}</p>
          </div>
          <div className="bg-indigo-800/30 rounded-lg p-3 text-center">
            <p className="text-sm text-indigo-300">Average Score</p>
            <p className="text-2xl font-bold text-white">{stats.averageScore}%</p>
          </div>
          <div className="bg-indigo-800/30 rounded-lg p-3 text-center">
            <p className="text-sm text-indigo-300">Best Category</p>
            <p className="text-xl font-bold text-white">{stats.bestCategory}</p>
          </div>
          <div className="bg-indigo-800/30 rounded-lg p-3 text-center">
            <p className="text-sm text-indigo-300">Total Points</p>
            <p className="text-2xl font-bold text-white">{stats.totalPoints}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function WebsiteStats() {
  const stats = [
    { title: "Total Quizzes", value: "1,337", icon: BookOpenIcon, color: "text-blue-400" },
    { title: "Active Users", value: "42,069", icon: UsersIcon, color: "text-green-400" },
    { title: "Questions Answered", value: "1.1M+", icon: BrainIcon, color: "text-yellow-400" },
    { title: "Avg. Daily Quizzes", value: 500, icon: ActivityIcon, color: "text-pink-400" },
  ];

  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden">
      <CardHeader className="bg-indigo-800/50">
        <CardTitle className="text-2xl font-bold text-white flex items-center">
          <StarIcon className="mr-2 h-6 w-6 text-yellow-400" />
          Website Stats
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {stats.map((stat, index) => (
            <div key={index} className="bg-indigo-800/30 rounded-lg p-4 text-center">
              <stat.icon className={`h-8 w-8 ${stat.color} mx-auto mb-2`} />
              <h3 className="text-lg font-semibold text-white">{stat.value}</h3>
              <p className="text-sm text-indigo-300">{stat.title}</p>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}