import React from 'react';

export function Footer() {
  return (
    <footer className="bg-[#1a1a3a]/80 backdrop-blur-md text-gray-300 py-8 z-10">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col items-center space-y-4">
          <h3 className="text-lg font-semibold">Connect With Us</h3>
          <a 
            href="https://discord.gg/dEAsWGkAdE" 
            target="_blank" 
            rel="noopener noreferrer"
            className="w-full max-w-xs bg-[#7289da] hover:bg-[#5e73bc] text-white font-bold py-2 px-4 rounded-md transition-all duration-300 flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 mr-2"><circle cx="12" cy="12" r="10"></circle><path d="M14.5 9a3.5 3.5 0 0 0-5 0v6a3.5 3.5 0 0 0 5 0"></path><path d="M8.5 14a3.5 3.5 0 0 0 5 0"></path><path d="M8.5 10a3.5 3.5 0 0 1 5 0"></path></svg>
            Join Our Discord
          </a>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>&copy; 2024 Blight Trivia. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}