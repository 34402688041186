import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/.netlify/functions';

export const fetchUserProfile = async (username) => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  try {
    const response = await axios.get(`${API_BASE_URL}/userProfile?username=${username}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (username, formData) => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  try {
    const response = await axios.put(`${API_BASE_URL}/updateUser`, formData, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      params: { username }
    });
    return response.data;
  } catch (error) {
    console.error('Error in updateUserProfile:', error.response || error);
    throw error;
  }
};