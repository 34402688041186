import React from 'react';
import { Button } from "./ui/button";
import { RocketIcon, BrainIcon, TrophyIcon, AwardIcon, UsersIcon } from "lucide-react";
import { NavButton } from './ui/NavButton';
import { UserMenu } from './UserMenu';
import { ActivityMenu } from './ActivityMenu';

export function NavigationBar({ 
  handleNavigation,
  isAuthenticated, 
  username,
  userLevel,
  userXP,
  xpProgress,
  xpForNext,
  userRole,
  setUserRole,
  levelRank,
  setLevelRank,
  setUserLevel,
  setShowSignUp,
  setShowLogin,
  handleLogout,
  changeUserRole,
  changeUserLevel,
  profilePicture
}) {
  return (
    <nav className="bg-[#1a1a3a]/90 backdrop-blur-md p-4 flex justify-between items-center z-20 sticky top-0">
      <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
        Blight Trivia
      </h1>
      <div className="flex items-center space-x-6">
        <NavButton
          icon={RocketIcon}
          label="Dashboard"
          onClick={() => handleNavigation('dashboard')}
        />
        <NavButton
          icon={BrainIcon}
          label="Quizzes"
          onClick={() => handleNavigation('quizzes')}
        />
        <NavButton
          icon={TrophyIcon}
          label="Tier Lists"
          onClick={() => handleNavigation('tierlist')}
        />
        <NavButton
          icon={AwardIcon}
          label="Leaderboard"
          onClick={() => handleNavigation('leaderboard')}
        />
        <NavButton
          icon={UsersIcon}
          label="Users"
          onClick={() => handleNavigation('users')}
        />
      </div>
      <div className="flex items-center space-x-4">
        {isAuthenticated ? (
          <>
            <ActivityMenu isAuthenticated={isAuthenticated} />
            <UserMenu 
              username={username}
              userLevel={userLevel}
              userXP={userXP}
              xpProgress={xpProgress}
              xpForNext={xpForNext}
              userRole={userRole}
              levelRank={levelRank}
              changeUserRole={changeUserRole}
              changeUserLevel={changeUserLevel}
              handleLogout={handleLogout}
              profilePicture={profilePicture}
            />
          </>
        ) : (
          <>
            <Button 
              variant="outline" 
              className="bg-purple-600 hover:bg-purple-700 text-white border-purple-400"
              onClick={() => setShowSignUp(true)}
            >
              Sign Up
            </Button>
            <Button 
              variant="outline" 
              className="bg-pink-600 hover:bg-pink-700 text-white border-pink-400"
              onClick={() => setShowLogin(true)}
            >
              Log In
            </Button>
          </>
        )}
      </div>
    </nav>
  );
}