import React, { useState, useRef, useEffect } from 'react';
import { ActivityIcon, ClockIcon, EyeOffIcon } from "lucide-react";
import axios from 'axios';

export function ActivityMenu({ isAuthenticated }) {
  const [showActivityDropdown, setShowActivityDropdown] = useState(false);
  const [activities, setActivities] = useState([]);
  const [unreadActivities, setUnreadActivities] = useState(0);
  const [showAllActivities, setShowAllActivities] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activityDropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (activityDropdownRef.current && !activityDropdownRef.current.contains(event.target)) {
        setShowActivityDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchActivities();
    }
  }, [isAuthenticated]);

  const fetchActivities = async (all = false) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) return;
      
      const url = all ? '/api/user/all-activities' : '/api/user/activities';
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setActivities(response.data.recentActivities);
      if (!all) {
        setUnreadActivities(response.data.recentActivities.length);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const markActivitiesAsRead = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      if (!token) return;
      
      await axios.post('/api/user/mark-activities-read', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUnreadActivities(0);
    } catch (error) {
      console.error('Error marking activities as read:', error);
    }
  };

  const toggleActivityDropdown = () => {
    setShowActivityDropdown(!showActivityDropdown);
    if (!showActivityDropdown) {
      markActivitiesAsRead();
    }
  };

  const toggleAllActivities = () => {
    setShowAllActivities(!showAllActivities);
    fetchActivities(!showAllActivities);
  };

  return (
    <div className="relative" ref={activityDropdownRef}>
      <button
        onClick={toggleActivityDropdown}
        className="relative p-2 text-gray-400 hover:text-white focus:outline-none"
      >
        <ActivityIcon className="h-6 w-6" />
        {unreadActivities > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
            {unreadActivities}
          </span>
        )}
      </button>
      {showActivityDropdown && (
        <div className="absolute right-0 mt-2 w-80 bg-[#1a1a3a] rounded-md shadow-lg py-1 z-50 transition-all duration-300 ease-in-out">
          <div className="px-4 py-2 font-semibold text-white border-b border-gray-700">
            <span>{showAllActivities ? 'All Activities' : 'Recent Activities'}</span>
          </div>
          <div className="max-h-96 overflow-y-auto transition-all duration-300 ease-in-out custom-scrollbar">
            {isLoading ? (
              <div className="px-4 py-2 text-gray-400">Loading...</div>
            ) : activities.length > 0 ? (
              activities.map((activity, index) => (
                <div key={index} className="px-4 py-2 hover:bg-[#2a2a4a] text-white transition-all duration-300 ease-in-out">
                  <p>{activity.action}</p>
                  <p className="text-xs text-gray-400">{new Date(activity.timestamp).toLocaleString()}</p>
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-gray-400">No activities to display</div>
            )}
          </div>
          <div className="px-4 py-2 border-t border-gray-700">
            <button
              onClick={toggleAllActivities}
              className="w-full text-xs bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition-all duration-300 ease-in-out"
              disabled={isLoading}
            >
              {showAllActivities ? (
                <>
                  <EyeOffIcon className="h-4 w-4 mr-1" />
                  Hide History
                </>
              ) : (
                <>
                  <ClockIcon className="h-4 w-4 mr-1" />
                  Show History
                </>
              )}
            </button>
          </div>
        </div>
      )}
      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #2a2a4a;
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #4a4a6a;
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #5a5a7a;
        }
      `}</style>
    </div>
  );
}