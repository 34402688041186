import React from 'react';

export function NavButton({ icon: Icon, label, onClick }) {
  return (
    <button
      onClick={onClick}
      className="flex items-center space-x-1 text-gray-300 hover:text-white transition-colors"
    >
      <Icon className="h-5 w-5" />
      <span>{label}</span>
    </button>
  );
}