import React from 'react';
import { Button } from "./ui/button";

export function SignUpModal({ showSignUp, setShowSignUp, username, setUsername, password, setPassword, rememberMe, setRememberMe, handleSignUp, setMessage }) {
  return (
    showSignUp && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#1a1a3a] p-8 rounded-lg shadow-lg max-w-md w-full relative">
          <button 
            onClick={() => setShowSignUp(false)} 
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            ✕
          </button>
          <h2 className="text-2xl font-bold mb-6 text-center text-white">Sign Up</h2>
          <form onSubmit={handleSignUp} className="space-y-4">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 bg-[#2a2a4a] rounded text-white"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 bg-[#2a2a4a] rounded text-white"
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="rememberMe" className="text-white">Remember me</label>
            </div>
            <Button
              type="submit"
              className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Sign Up
            </Button>
          </form>
        </div>
      </div>
    )
  );
}

export function LoginModal({ showLogin, setShowLogin, username, setUsername, password, setPassword, rememberMe, setRememberMe, handleLogin, setMessage }) {
  return (
    showLogin && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#1a1a3a] p-8 rounded-lg shadow-lg max-w-md w-full relative">
          <button 
            onClick={() => setShowLogin(false)} 
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            ✕
          </button>
          <h2 className="text-2xl font-bold mb-6 text-center text-white">Log In</h2>
          <form onSubmit={handleLogin} className="space-y-4">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 bg-[#2a2a4a] rounded text-white"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 bg-[#2a2a4a] rounded text-white"
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="rememberMe" className="text-white">Remember me</label>
            </div>
            <Button
              type="submit"
              className="w-full bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Log In
            </Button>
          </form>
        </div>
      </div>
    )
  );
}