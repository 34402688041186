import React, { useState, useRef, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";
import { Progress } from "./ui/progress";
import { Badge } from "./ui/badge";
import { RocketIcon, UsersIcon, GlobeIcon, PlusCircleIcon, ListIcon, StarIcon, SortAscIcon, SearchIcon } from "lucide-react";

export function Quizzes() {
  return (
    <div className="space-y-8">
      <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.8)]">
        Explore the Blight Quiz Universe
      </h1>
      <Tabs defaultValue="blight" className="w-full">
        <TabsList className="grid w-full grid-cols-3 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 rounded-lg p-1">
          <TabsTrigger value="blight" className="flex items-center justify-center space-x-2 py-3">
            <RocketIcon className="w-5 h-5" />
            <span>Blight</span>
          </TabsTrigger>
          <TabsTrigger value="user-created" className="flex items-center justify-center space-x-2 py-3">
            <UsersIcon className="w-5 h-5" />
            <span>Created by Users</span>
          </TabsTrigger>
          <TabsTrigger value="other" className="flex items-center justify-center space-x-2 py-3">
            <GlobeIcon className="w-5 h-5" />
            <span>Other</span>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="blight">
          <BlightQuizzes />
        </TabsContent>
        <TabsContent value="user-created">
          <UserCreatedQuizzes />
        </TabsContent>
        <TabsContent value="other">
          <OtherQuizzes />
        </TabsContent>
      </Tabs>
    </div>
  );
}

function BlightQuizzes() {
  const [sortBy, setSortBy] = useState('rating');
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleQuizzes, setVisibleQuizzes] = useState(3);
  const initialVisibleQuizzes = 3;
  const memberQuizzes = [
    { title: "Custom name", author: "Toght", icon: "👨‍🚀", progress: 90, description: "You can add a custom description", plays: 150, rating: 4.8, createdAt: new Date('2023-05-15') },
    { title: "Ducky's Test", author: "oDuckmonds", icon: "🌌", progress: 45, description: "Some description this is", plays: 120, rating: 4.6, createdAt: new Date('2023-06-20') },
    { title: "Frams's Quiz", author: "Frams", icon: "🔬", progress: 100, description: "How well do you know me lil bro!", plays: 100, rating: 5, createdAt: new Date('2023-04-10') },
    { title: "Ronny bonny", author: "Ronan", icon: "⚛️", progress: 1, description: "Aight gang", plays: 90, rating: 1, createdAt: new Date('2023-07-01') },
  ];

  const filteredQuizzes = memberQuizzes.filter(quiz => 
    quiz.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    quiz.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedQuizzes = [...filteredQuizzes].sort((a, b) => {
    switch (sortBy) {
      case 'rating':
        return b.rating - a.rating;
      case 'newest':
        return b.createdAt - a.createdAt;
      case 'oldest':
        return a.createdAt - b.createdAt;
      case 'mostPlayed':
        return b.plays - a.plays;
      default:
        return 0;
    }
  });

  const loadMore = () => {
    setVisibleQuizzes(prevVisible => prevVisible + 3);
  };

  const showLess = () => {
    setVisibleQuizzes(initialVisibleQuizzes);
  };

  return (
    <div className="space-y-8 mt-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <QuizCard 
          title="Blight Overall" 
          icon="🌌" 
          progress={50} 
          description="A comprehensive quiz covering all aspects of Blight."
        />
        <QuizCard 
          title="Blight History" 
          icon="🌠" 
          progress={75} 
          description="Dive into the rich history of Blight and test your knowledge!"
        />
      </div>
      
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white">Member Quizzes</h2>
          <div className="flex items-center space-x-4">
            <SortButton sortBy={sortBy} setSortBy={setSortBy} />
            <Button className="bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center">
              <PlusCircleIcon className="w-5 h-5 mr-2" />
              Create Member Quiz
            </Button>
          </div>
        </div>
        <div className="relative">
          <Input
            type="text"
            placeholder="Search quizzes or users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full bg-indigo-900/50 border-indigo-500 text-white placeholder-indigo-300"
          />
          <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-300" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {sortedQuizzes.slice(0, visibleQuizzes).map((quiz, index) => (
            <MemberQuizCard 
              key={index}
              title={quiz.title} 
              author={quiz.author}
              icon={quiz.icon} 
              progress={quiz.progress} 
              description={quiz.description}
              plays={quiz.plays}
              rating={quiz.rating}
            />
          ))}
        </div>
        <div className="flex justify-center mt-8 space-x-4">
          {visibleQuizzes < sortedQuizzes.length && (
            <Button 
              onClick={loadMore}
              className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center"
            >
              <ListIcon className="w-5 h-5 mr-2" />
              Show More
            </Button>
          )}
          {visibleQuizzes > initialVisibleQuizzes && (
            <Button 
              onClick={showLess}
              className="bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center"
            >
              <ListIcon className="w-5 h-5 mr-2" />
              Show Less
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function MemberQuizCard({ title, author, icon, progress, description, plays, rating }) {
  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden group hover:shadow-lg hover:shadow-purple-500/50 transition-all duration-300 transform hover:scale-105">
      <CardHeader className="bg-indigo-800/50 border-b border-indigo-700">
        <CardTitle className="text-xl font-bold text-white flex items-center">
          <span className="text-2xl mr-2">{icon}</span>
          <span className="text-white">
            {title}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 p-6">
        <CardDescription className="text-indigo-200 text-sm">
          {description}
        </CardDescription>
        <div className="flex justify-between items-center text-sm">
          <span className="text-indigo-300">By {author}</span>
          <Badge variant="secondary" className="bg-indigo-700 text-white flex items-center">
            <StarIcon className="w-3 h-3 mr-1" />
            {rating.toFixed(1)}
          </Badge>
        </div>
        <div className="flex justify-between text-sm">
          <span className="text-indigo-300">Progress</span>
          <span className="text-indigo-200 font-semibold">{progress}%</span>
        </div>
        <Progress value={progress} className="h-2 bg-indigo-950">
          <div
            className="h-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full"
            style={{ width: `${progress}%` }}
          />
        </Progress>
        <div className="flex justify-between items-center text-sm">
          <span className="text-indigo-300">{plays} plays</span>
          <Button className="bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 transform hover:scale-105 hover:shadow-md hover:shadow-purple-500/50 group">
            <span className="mr-2 group-hover:mr-3 transition-all">Start Quiz</span>
            <span className="opacity-0 group-hover:opacity-100 transition-all">→</span>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

function UserCreatedQuizzes() {
  const [sortBy, setSortBy] = useState('rating');
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleQuizzes, setVisibleQuizzes] = useState(6);
  const initialVisibleQuizzes = 6;
  const userQuizzes = [
    { title: "Obscure Movie Quotes", author: "CinemaGeek42", icon: "🎬", progress: 35, description: "Test your knowledge of lesser-known movie quotes from cult classics.", plays: 87, rating: 4.2, createdAt: new Date('2023-06-18') },
    { title: "Extinct Animals Trivia", author: "DinoLover99", icon: "🦕", progress: 62, description: "How much do you know about animals that no longer roam the Earth?", plays: 103, rating: 4.5, createdAt: new Date('2023-05-30') },
    { title: "Conspiracy Theories 101", author: "TruthSeeker007", icon: "🕵️", progress: 28, description: "Explore the wild world of popular conspiracy theories. Tin foil hat optional!", plays: 156, rating: 3.9, createdAt: new Date('2023-07-05') },
    { title: "Cheese Connoisseur Challenge", author: "CheeseWhiz", icon: "🧀", progress: 75, description: "From Gouda to Roquefort, test your cheese expertise!", plays: 92, rating: 4.7, createdAt: new Date('2023-04-12') },
    { title: "Fictional Languages Quiz", author: "LinguistNerd", icon: "🗣️", progress: 40, description: "Klingon, Elvish, Dothraki - how well do you know made-up languages?", plays: 68, rating: 4.3, createdAt: new Date('2023-06-25') },
    { title: "Extreme Weather Phenomena", author: "StormChaser", icon: "🌪️", progress: 55, description: "From fire tornadoes to moonbows, explore Earth's wildest weather events!", plays: 112, rating: 4.6, createdAt: new Date('2023-05-08') },
    { title: "Forgotten Inventions", author: "GadgetGuru", icon: "💡", progress: 20, description: "Discover quirky inventions that didn't stand the test of time.", plays: 79, rating: 4.0, createdAt: new Date('2023-07-11') },
    { title: "Mythical Creatures Showdown", author: "LegendHunter", icon: "🐉", progress: 88, description: "Pit legendary beasts against each other in this mythical creature face-off!", plays: 201, rating: 4.8, createdAt: new Date('2023-04-30') },
    { title: "Quantum Physics for Dummies", author: "SchrödingersCat", icon: "⚛️", progress: 15, description: "Simplifying mind-bending quantum concepts for the curious mind.", plays: 54, rating: 4.1, createdAt: new Date('2023-06-09') },
    { title: "Bizarre World Records", author: "OddballChamp", icon: "🏆", progress: 70, description: "Think you know weird? Test your knowledge of the strangest world records!", plays: 135, rating: 4.4, createdAt: new Date('2023-05-22') },
    { title: "Culinary Disasters", author: "KitchenNightmare", icon: "🍳", progress: 45, description: "From burnt offerings to flavor abominations, test your food fail knowledge!", plays: 98, rating: 4.2, createdAt: new Date('2023-07-03') },
    { title: "Unsolved Internet Mysteries", author: "DigitalDetective", icon: "🔍", progress: 30, description: "Dive into the weird and unexplained corners of the world wide web.", plays: 167, rating: 4.5, createdAt: new Date('2023-04-18') },
    { title: "Retro Gaming Nostalgia", author: "8BitWizard", icon: "🕹️", progress: 95, description: "Travel back to the golden age of pixelated adventures and chiptunes.", plays: 189, rating: 4.9, createdAt: new Date('2023-06-01') },
    { title: "Absurd Laws Around the World", author: "LegalEagle", icon: "⚖️", progress: 50, description: "Explore the most bizarre and outdated laws still on the books globally.", plays: 76, rating: 4.0, createdAt: new Date('2023-05-15') },
    { title: "Cryptozoology Challenge", author: "MythBuster", icon: "👣", progress: 65, description: "Bigfoot, Nessie, and beyond - how much do you know about cryptids?", plays: 123, rating: 4.3, createdAt: new Date('2023-07-08') },
    { title: "Forgotten 90s Pop Culture", author: "MillennialNostalgia", icon: "📼", progress: 85, description: "Relive the most obscure trends and fads of the 1990s!", plays: 145, rating: 4.6, createdAt: new Date('2023-04-25') },
    { title: "Paradoxes and Brain Teasers", author: "MindBender", icon: "🧠", progress: 25, description: "Prepare to have your mind twisted with logical paradoxes and riddles.", plays: 88, rating: 4.2, createdAt: new Date('2023-06-20') },
    { title: "Fictional Brand Quiz", author: "AdWizard", icon: "🛍️", progress: 60, description: "How well do you know the fake brands from your favorite movies and TV shows?", plays: 109, rating: 4.4, createdAt: new Date('2023-05-05') },
  ];

  const filteredQuizzes = userQuizzes.filter(quiz => 
    quiz.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    quiz.author.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedQuizzes = [...filteredQuizzes].sort((a, b) => {
    switch (sortBy) {
      case 'rating':
        return b.rating - a.rating;
      case 'newest':
        return b.createdAt - a.createdAt;
      case 'oldest':
        return a.createdAt - b.createdAt;
      case 'mostPlayed':
        return b.plays - a.plays;
      default:
        return 0;
    }
  });

  const loadMore = () => {
    setVisibleQuizzes(prevVisible => prevVisible + 6);
  };

  const showLess = () => {
    setVisibleQuizzes(initialVisibleQuizzes);
  };

  return (
    <div className="mt-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">User-Created Quizzes</h2>
        <div className="flex items-center space-x-4">
          <SortButton sortBy={sortBy} setSortBy={setSortBy} />
          <Button className="bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center">
            <PlusCircleIcon className="w-5 h-5 mr-2" />
            Create New Quiz
          </Button>
        </div>
      </div>
      <div className="relative">
        <Input
          type="text"
          placeholder="Search quizzes or users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10 pr-4 py-2 w-full bg-indigo-900/50 border-indigo-500 text-white placeholder-indigo-300"
        />
        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-300" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedQuizzes.slice(0, visibleQuizzes).map((quiz, index) => (
          <MemberQuizCard
            key={index}
            title={quiz.title}
            author={quiz.author}
            icon={quiz.icon}
            progress={quiz.progress}
            description={quiz.description}
            plays={quiz.plays}
            rating={quiz.rating}
          />
        ))}
      </div>
      <div className="flex justify-center mt-8 space-x-4">
        {visibleQuizzes < sortedQuizzes.length && (
          <Button 
            onClick={loadMore}
            className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center"
          >
            <ListIcon className="w-5 h-5 mr-2" />
            Load More
          </Button>
        )}
        {visibleQuizzes > initialVisibleQuizzes && (
          <Button 
            onClick={showLess}
            className="bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center"
          >
            <ListIcon className="w-5 h-5 mr-2" />
            Show Less
          </Button>
        )}
      </div>
    </div>
  );
}

function SortButton({ sortBy, setSortBy }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <Button
        className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 flex items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SortAscIcon className="w-5 h-5 mr-2" />
        Sort By: {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
      </Button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-indigo-900 rounded-md shadow-lg overflow-hidden z-20">
          {['rating', 'newest', 'oldest', 'mostPlayed'].map((option) => (
            <button
              key={option}
              className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-indigo-800 transition-colors"
              onClick={() => {
                setSortBy(option);
                setIsOpen(false);
              }}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

function OtherQuizzes() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
      <QuizCard 
        title="Advanced Mathematics" 
        icon="🔢" 
        progress={40} 
        description="Challenge yourself with complex mathematical problems and theories."
      />
      <QuizCard 
        title="World Sports" 
        icon="🏆" 
        progress={65} 
        description="Test your knowledge of various sports from around the globe."
      />
      <QuizCard 
        title="Global Geography" 
        icon="🌍" 
        progress={55} 
        description="Explore the world's landscapes, countries, and geographical features."
      />
      <QuizCard 
        title="Modern Physics" 
        icon="⚛️" 
        progress={70} 
        description="Delve into the fascinating world of contemporary physics theories."
      />
      <QuizCard 
        title="World History" 
        icon="🏛️" 
        progress={30} 
        description="Journey through time and test your understanding of major historical events."
      />
      <QuizCard 
        title="Pop Culture Trends" 
        icon="🎭" 
        progress={80} 
        description="Stay up-to-date with the latest trends in music, movies, and entertainment!"
      />
    </div>
  );
}

function QuizCard({ title, icon, progress, description }) {
  return (
    <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2 overflow-hidden group hover:shadow-lg hover:shadow-purple-500/50 transition-all duration-300 transform hover:scale-105">
      <CardHeader className="bg-indigo-800/50 border-b border-indigo-700">
        <CardTitle className="text-2xl font-bold text-white flex items-center">
          <span className="text-3xl mr-3 w-8 h-8 flex items-center justify-center">{icon}</span>
          <span className="text-white">
            {title}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 p-6">
        <CardDescription className="text-indigo-200 text-base">
          {description}
        </CardDescription>
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-indigo-300">Progress</span>
            <span className="text-indigo-200 font-semibold">{progress}%</span>
          </div>
          <Progress value={progress} className="h-2 bg-indigo-950">
            <div
              className="h-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full"
              style={{ width: `${progress}%` }}
            />
          </Progress>
        </div>
        <Button className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white font-bold py-3 px-4 rounded-full transition-all duration-300 transform hover:scale-105 hover:shadow-md hover:shadow-purple-500/50 group">
          <span className="mr-2 group-hover:mr-3 transition-all">Start Quiz</span>
          <span className="opacity-0 group-hover:opacity-100 transition-all">→</span>
        </Button>
      </CardContent>
    </Card>
  );
}