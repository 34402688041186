const BASE_XP = 100;

const GROWTH_FACTOR = 1.5;

const MAX_LEVEL = 100;

/**
 * Calculate the level based on total XP
 * @param {number} xp - Total XP
 * @returns {number} - Current level
 */
export function calculateLevel(xp) {
  let level = 1;
  let xpForNextLevel = BASE_XP;

  while (xp >= xpForNextLevel && level < MAX_LEVEL) {
    xp -= xpForNextLevel;
    level++;
    xpForNextLevel = Math.floor(BASE_XP * Math.pow(GROWTH_FACTOR, level - 1));
  }

  return level;
}

/**
 * Calculate XP required for the next level
 * @param {number} currentLevel - Current level
 * @returns {number} - XP required for the next level
 */
export function xpForNextLevel(currentLevel) {
  return Math.floor(BASE_XP * Math.pow(GROWTH_FACTOR, currentLevel - 1));
}

/**
 * Add XP and calculate new level and remaining XP
 * @param {number} currentXP - Current total XP
 * @param {number} xpToAdd - XP to add
 * @returns {Object} - New level, new total XP, and XP progress towards next level
 */
export function addXP(currentXP, xpToAdd) {
  const newTotalXP = currentXP + xpToAdd;
  const newLevel = calculateLevel(newTotalXP);
  const xpForNext = xpForNextLevel(newLevel);
  const xpProgress = newTotalXP - calculateXPForLevel(newLevel);

  return {
    level: newLevel,
    totalXP: newTotalXP,
    xpProgress,
    xpForNextLevel: xpForNext
  };
}

/**
 * Calculate total XP required to reach a specific level
 * @param {number} level - Target level
 * @returns {number} - Total XP required
 */
export function calculateXPForLevel(level) {
  let totalXP = 0;
  for (let i = 1; i < level; i++) {
    totalXP += Math.floor(BASE_XP * Math.pow(GROWTH_FACTOR, i - 1));
  }
  return totalXP;
}