import { useState, useEffect } from 'react';
import axios from 'axios';
import { handleXPUpdate } from '../utils/xpHandler';
import { getLevelRank } from '../utils/rankSystem';

const API_URL = process.env.REACT_APP_API_URL || '/.netlify/functions/auth';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [userXP, setUserXP] = useState(0);
  const [userLevel, setUserLevel] = useState(1);
  const [xpProgress, setXPProgress] = useState(0);
  const [xpForNext, setXPForNext] = useState(100);
  const [userRole, setUserRole] = useState('MEMBER');
  const [levelRank, setLevelRank] = useState('Novice');
  const [profilePicture, setProfilePicture] = useState('');

  const handleSignUp = async (username, password) => {
    try {
      const response = await axios.post(API_URL, {
        action: 'register',
        username,
        password
      });
      
      const { token, ...userData } = response.data;
      handleAuthSuccess(token, userData, false);
      return { success: true };
    } catch (error) {
      console.error('Sign up error:', error);
      return { success: false, message: error.response?.data?.message || 'An error occurred during sign up' };
    }
  };

  const handleLogin = async (username, password, rememberMe) => {
    try {
      const response = await axios.post(API_URL, {
        action: 'login',
        username,
        password
      });
      
      const { token, ...userData } = response.data;
      handleAuthSuccess(token, userData, rememberMe);
      return { success: true };
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, message: error.response?.data?.message || 'An error occurred during login' };
    }
  };

  const handleAuthSuccess = (token, userData, rememberMe) => {
    const storage = rememberMe ? localStorage : sessionStorage;
    storage.setItem('token', token);
    storage.setItem('userData', JSON.stringify(userData));
    updateUserData(userData);
    setIsAuthenticated(true);
  };

  const updateUserData = (userData) => {
    setUsername(userData.username);
    setUserRole(userData.role);
    setUserXP(userData.xp);
    setUserLevel(userData.level);
    setLevelRank(getLevelRank(userData.level));
    setProfilePicture(userData.profilePicture || '');
    handleXPUpdate(userData.xp, setUserXP, setUserLevel, setXPProgress, setXPForNext, setLevelRank);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userData');
    sessionStorage.removeItem('userData');
    setIsAuthenticated(false);
    setUsername('');
    setUserXP(0);
    setUserLevel(1);
    setXPProgress(0);
    setXPForNext(100);
    setUserRole('MEMBER');
    setLevelRank('Novice');
    setProfilePicture('');
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const storedUserData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
      if (token && storedUserData) {
        const userData = JSON.parse(storedUserData);
        setIsAuthenticated(true);
        updateUserData(userData);
      }
    };

    checkAuthStatus();
  }, []);

  return {
    isAuthenticated,
    username,
    userXP,
    userLevel,
    xpProgress,
    xpForNext,
    userRole,
    levelRank,
    profilePicture,
    setUsername,
    handleSignUp,
    handleLogin,
    handleLogout,
    updateUserData
  };
}