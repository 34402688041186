// User role ranks
const userRoles = {
  MEMBER: 'MEMBER',
  MODERATOR: 'MODERATOR',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER'
};

// Level achievement ranks
const levelRanks = [
  { level: 1, rank: 'Novice' },
  { level: 3, rank: 'Rookie' },
  { level: 5, rank: 'Trainee' },
  { level: 8, rank: 'Learner' },
  { level: 10, rank: 'Apprentice' },
  { level: 13, rank: 'Quiz Enthusiast' },
  { level: 16, rank: 'Explorer' },
  { level: 20, rank: 'Challenger' },
  { level: 24, rank: 'Thinker' },
  { level: 28, rank: 'Adept' },
  { level: 33, rank: 'Expert' },
  { level: 38, rank: 'Quiz Mastermind' },
  { level: 44, rank: 'Quiz Wizard' },
  { level: 50, rank: 'Quiz Sage' },
  { level: 57, rank: 'Quiz Champion' },
  { level: 65, rank: 'Quiz Maestro' },
  { level: 70, rank: 'Brainiac' },
  { level: 78, rank: 'Quiz Overlord' },
  { level: 84, rank: 'Quiz Legend' },
  { level: 90, rank: 'Quiz Titan' },
  { level: 95, rank: 'Supreme Quizmaster' },
  { level: 100, rank: 'Cosmic Quizmaster' }
];

/**
 * Get the rank title for a given level
 * @param {number} level - The user's current level
 * @returns {string} - The rank title for the given level
 */
function getLevelRank(level) {
  for (let i = levelRanks.length - 1; i >= 0; i--) {
    if (level >= levelRanks[i].level) {
      return levelRanks[i].rank;
    }
  }
  return 'Unranked';
}

/**
 * Get the next rank and the level required to achieve it
 * @param {number} currentLevel - The user's current level
 * @returns {Object} - The next rank and the level required to achieve it
 */
function getNextRank(currentLevel) {
  for (let i = 0; i < levelRanks.length; i++) {
    if (currentLevel < levelRanks[i].level) {
      return {
        nextRank: levelRanks[i].rank,
        levelRequired: levelRanks[i].level
      };
    }
  }
  return null; // Return null if the user has reached the highest rank
}

module.exports = {
  userRoles,
  levelRanks,
  getLevelRank,
  getNextRank
};