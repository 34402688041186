import React from 'react';

export function Card({ children, className, ...props }) {
  return (
    <div className={`rounded-lg shadow-md ${className}`} {...props}>
      {children}
    </div>
  );
}

export function CardHeader({ children, ...props }) {
  return <div className="p-4" {...props}>{children}</div>;
}

export function CardTitle({ children, ...props }) {
  return <h2 className="text-xl font-bold" {...props}>{children}</h2>;
}

export function CardDescription({ children, ...props }) {
  return <p className="text-sm text-gray-500" {...props}>{children}</p>;
}

export function CardContent({ children, ...props }) {
  return <div className="p-4" {...props}>{children}</div>;
}