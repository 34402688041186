import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { fetchUserProfile } from '../api/userProfileApi';
import { StatCard } from './StatCard';

const DEFAULT_AVATAR = "/default-avatar.png";

export function UserProfile() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const userData = await fetchUserProfile(username);
        setUser(userData);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load user profile: ' + (err.response?.data?.message || err.message));
        setIsLoading(false);
      }
    };

    loadUserProfile();
  }, [username]);

  const handleEditProfile = () => {
    navigate(`/profile/edit/${username}`);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!user) return <div>User not found</div>;

  return (
    <div className="container mx-auto p-4">
      <Card className="bg-gradient-to-br from-indigo-900 to-purple-900 border-indigo-500/50 border-2">
        <CardHeader>
          <CardTitle className="text-3xl font-bold text-white">{user.username}'s Profile</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex items-center space-x-4">
            <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-indigo-400">
              <img
                src={user.profilePicture || DEFAULT_AVATAR}
                alt={user.username}
                className="w-full h-full object-cover object-center"
              />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-white">{user.username}</h2>
              <p className="text-indigo-300">{user.role}</p>
            </div>
          </div>
          <div className="text-white">
            <p>{user.description || "No description provided."}</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <StatCard title="Level" value={user.level} />
            <StatCard title="XP" value={user.xp} />
            <StatCard title="Rank" value={user.rank} />
            <StatCard title="Quizzes Completed" value={user.quizzesCompleted} />
            <StatCard title="Questions Answered" value={user.questionsAnswered} />
            <StatCard title="Quizzes Created" value={user.quizzesCreated} />
          </div>
          <div>
            <p className="text-indigo-300">Account created on: {new Date(user.createdAt).toLocaleDateString()}</p>
          </div>
          {user.isOwnProfile && (
            <Button className="bg-blue-500 hover:bg-blue-600 text-white" onClick={handleEditProfile}>
              Edit Profile
            </Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
}